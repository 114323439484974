<template>
  <div class="fill-height">
    <div class="fill-height">
      <div class="basketArea py-10">
        <div class="titleBasket">
          {{ $t('wallet.title') }}
        </div>
        <div class="basketContent mt-10">
          <CartComponent v-if="!cartIsEmpty"/>
          <div class="text-center" v-else>
            <img src="../../assets/EmptyState.svg" width="250px" alt="">
            <p>
              {{ $t('emptyStateCart') }}
            </p>
            <v-btn color="blue" text :to="{name:'packages'}">
              {{ $t('packages.buy') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/*eslint-disable*/
import CartComponent from "@/components/Basket/CartComponent";
import {mapGetters} from "vuex";

export default {
  components: {CartComponent},
  computed: {
    ...mapGetters({
      cart: 'getCart',
    }),
  },
  data() {
    return {
      cartIsEmpty: true,
    }
  },
  watch: {
    cart() {
      this.getStatusCart();
    }
  },
  methods: {
    getStatusCart() {
      // console.log(Object.keys(this/.cart.items))
      if (this.cart) {
        if (Object.keys(this.cart.items).length == 0) {
          this.cartIsEmpty = true
          // console.log('empty')
        } else {
          this.cartIsEmpty = false
          // console.log('not empty')
        }
      }
    }
  },
  mounted() {
    this.getStatusCart();
  }
}
</script>

<style scoped>
.basketArea {
  min-height: 80%;
  /*max-height: auto;*/
  height: auto;
  width: 99%;
  margin: 0px auto;
  border-radius: 18px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  padding: 20px;

}

.titleBasket {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: rgb(0, 0, 0);
}
</style>